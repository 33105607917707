import { useEffect, useRef } from 'react';

import useScrollBarPage from './useScrollbarPage';
import { useScrollBar } from './useScrollBar';

const usePageScrollPosition = (doTheUse: boolean): void => {
  const { goToPositionInScroll, scrollBar } = useScrollBar();
  const { isTheActualPageSave, pageScrollPosition, setScrollPosition } = useScrollBarPage();
  const isFirst = useRef(true);

  const functionRef = useRef<(top: number) => void>(null);
  const getScrollHeightRef = useRef<() => number>(null);

  useEffect(() => {
    functionRef.current = scrollBar?.scrollTop;
    getScrollHeightRef.current = scrollBar?.getScrollHeight;

    if (!doTheUse) {
      return undefined;
    }

    if (!isTheActualPageSave() || !pageScrollPosition) {
      if (!isFirst.current) {
        isFirst.current = true;
        return undefined;
      }
      goToPositionInScroll();
      return undefined;
    }

    functionRef.current(pageScrollPosition?.Y);
    const cycleToScroll = setInterval(() => {
      functionRef.current(pageScrollPosition?.Y);
      if (pageScrollPosition?.Y <= getScrollHeightRef.current()) {
        clearInterval(cycleToScroll);
        isFirst.current = false;
        setScrollPosition(undefined);
      }
    }, 100);

    return (): void => {
      clearInterval(cycleToScroll);
    };
  }, [doTheUse, scrollBar, pageScrollPosition]);
};

export default usePageScrollPosition;
