import { positionValues } from 'react-custom-scrollbars';
import { getParsedSortAndOrderFilters } from '../../../../utils/utils';
import { Book, CurrentProductsQueryVariables } from '../../../../graphqlTypes';
import { ProductsState } from '../../../../reducer/productsList/products.state';
import { getDataFromStorage, NameStorage } from '../../../../utils/services/localStorage.service';
import constants from '../../../../utils/constants';
import { Filter } from '../../Filters/model/filters.model';

const { allProductTypes, sectionWithAreasOfInterest, pixelToLoad } = constants;

export const hasAreaOfInterest = (): boolean =>
  !!getDataFromStorage<number>(NameStorage.areaOfInterest);

export const getSectionName = (
  section: keyof ProductsState | string,
  asPath: string,
  isFilter: boolean,
  existAreaOfInterest: boolean,
): keyof ProductsState => {
  const isResult = asPath.includes('resultados');

  if (isFilter) {
    return 'filters';
  }

  if (!section && isResult) return 'results';
  if (isResult) return `${section}Result` as unknown as keyof ProductsState;
  if (section === 'book' && existAreaOfInterest) return 'bookWithArea';
  if (section === 'ebook' && existAreaOfInterest) return 'ebookWithArea';

  return section as unknown as keyof ProductsState;
};

export const getSectionNameIfFilter = (isFilter: boolean): keyof ProductsState => {
  if (isFilter) {
    return 'filters';
  }

  return null;
};

/**
 * Validate if all products are unique
 * @param listProducts the products from query
 * @param initLoad true if filter, false if pagination
 * @param currentProducts the products in array
 * @param stateListProductsId the products id in array
 * @param pageIncludeResult true if is page result
 * @returns
 */
export const productsToShow = (
  listProducts: Book[],
  initLoad: boolean,
  currentProducts: Book[],
  stateListProductsId: number[],
  pageIncludeResult: boolean,
  pageIncludeFilters: boolean,
): { listProductsId: number[]; products: Book[] } => {
  const products = initLoad ? [] : [...currentProducts];
  let listProductsId: number[] = initLoad ? [] : [...stateListProductsId];
  const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);

  if ((pageIncludeResult || pageIncludeFilters) && initLoad && areaOfInterestFromStorage) {
    listProductsId = [];
  } else if (currentProducts.length && !stateListProductsId.length) {
    listProductsId = currentProducts.map((product) => product.id);
  }

  listProducts.forEach((element) => {
    if (!listProductsId.some((id) => id === element.id)) {
      listProductsId.push(element.id);
      products.push(element);
    }
  });

  return {
    listProductsId,
    products,
  };
};

/**
 *
 * @param filters
 * @param order
 * @param isPageBooksOrEbooks
 * @param area_prox
 * @param limit
 * @param skip
 * @param category
 * @param type
 * @param search
 * @param prox
 * @param areasOfInterest
 * @returns
 */
export const currentProductsVariable = (
  filters: Filter,
  order: string,
  isPageBooksOrEbooks: boolean,
  area_prox: string,
  limit: number,
  skip: number,
  category: string,
  type: string,
  search: string,
  prox: boolean,
): CurrentProductsQueryVariables => {
  let orderAbc = order || null;
  if (order === '') {
    orderAbc = isPageBooksOrEbooks ? 'calendar' : 'visited';
  }

  const { sortType: sort, orderType } = getParsedSortAndOrderFilters(orderAbc, area_prox);

  let product_type = null;
  if (filters?.['Categorías']?.length) {
    product_type = filters['Categorías'];
  } else {
    product_type = category ? [category] : allProductTypes;
  }
  const variables: CurrentProductsQueryVariables = {
    limit,
    skip,
    sort,
    order: orderType,
    product_type,
    lang: filters?.Idioma?.length ? filters.Idioma : null,
    rangePrice: filters?.Precio?.length ? filters.Precio[0] : null,
    areas_of_interest: filters?.Áreas?.length && filters.Áreas,
    tags: filters?.Tags?.length ? filters.Tags : null,
    type,
    searchGeneral: search,
    prox,
    area_prox,
  };

  if (!sectionWithAreasOfInterest.includes(variables.product_type[0])) {
    variables.areas_of_interest = null;
    return variables;
  }

  const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);
  variables.areas_of_interest = areaOfInterestFromStorage && [areaOfInterestFromStorage];

  if (!variables.areas_of_interest && areaOfInterestFromStorage) {
    variables.areas_of_interest = [areaOfInterestFromStorage];
  }

  return variables;
};

export const hasReachedBottom = (scrollbarValues: positionValues): boolean => {
  if (!scrollbarValues) return false;

  const { scrollHeight, clientHeight, scrollTop } = scrollbarValues;
  const height = scrollHeight - clientHeight;
  return height && height - scrollTop <= pixelToLoad;
};
