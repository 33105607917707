import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { isEqual } from 'lodash';
import shallow from 'zustand/shallow';

import useScrollBarPage from '../../../hooks/useScrollbarPage';
import {
  currentProductsVariable,
  getSectionNameIfFilter,
  hasReachedBottom,
  productsToShow,
} from './utils/productListing.utils';
import Loader from '../layout/Loader';
import constants from '../../../utils/constants';
import useFiltersAndOrder from '../../../hooks/useFiltersAndOrder';
import useStoreProducts from '../../../reducer/productsList/storeProducts';
import getKeyListProducts from '../../../hooks/useGetKeyListProducts';
import { getProducts, getProductsCount } from '../../../services/productListing.service';
import { getDataFromStorage, NameStorage } from '../../../utils/services/localStorage.service';
import { DataProducts } from '../../../reducer/productsList/products.state';
import { Book } from '../../../graphqlTypes';
import usePageScrollPosition from '../../../hooks/usePageScrollPosition';
import { PositionValues } from '../../../reducer/scrollbar/storeScrollbar';

const ProductPreview = dynamic(() => import('../productPreview/ProductPreview'));
const Button = dynamic(() => import('../forms/Button'));

const checkAreaType = (area: string): boolean => Number.isNaN(Number(area));
const {
  sectionWithAreasOfInterest,
  initialPagination,
  noInitialPagination,
  sectionSpanishWithAreasOfInterest,
} = constants;

interface ProductListingProps {
  area?: string;
  listProducts?: Book[];
  type?: string;
  search?: string;
  category?: string;
  title?: string;
  isAreaPage?: boolean;
  setListingLoading?: (data: boolean) => void;
  setTotalCurrentProducts?: React.Dispatch<React.SetStateAction<number>>;
}

interface ProductListingState {
  listProductsId: number[];
  loading: boolean;
  noPublish: boolean;
  initialLoad?: boolean;
  loadingBack?: boolean;
  areaBeautify?: string;
}

const ProductListing = ({
  area,
  type,
  search,
  category,
  title,
  isAreaPage,
  setListingLoading,
  setTotalCurrentProducts,
}: ProductListingProps): JSX.Element => {
  const {
    changeFilters,
    doTheSearch,
    existFilter,
    order,
    filter: filters,
    changeDoTheSearch,
  } = useFiltersAndOrder();
  const { pathname, asPath } = useRouter();
  const [state, setState] = useState<ProductListingState>({
    listProductsId: [],
    loading: false,
    noPublish: false,
    initialLoad: false,
    areaBeautify: area || '',
  });
  const [loaderResult, setLoaderResult] = useState<boolean>(false);
  const [_loading, setLoading] = useState<boolean>(false);
  const canLoadMore = useRef(true);
  const loadingProducts = useRef(false);
  const [productSelected, setProductSelected] = useState<Book>(null);
  const { pageScrollPosition, scrollbarValues, isTheActualPageSave } = useScrollBarPage();
  const scrollBarRef = useRef<PositionValues>(null);

  const sectionName = getKeyListProducts(category);
  const [productsState, setProductsState] = useStoreProducts(
    (stateStore) => [stateStore.dataProduct, stateStore.setStateBySection],
    shallow,
  );

  const {
    books: currentProducts = [],
    limit: limitCurrentProducts = initialPagination.limit,
    skip: skipCurrentProducts = initialPagination.skip,
  } = (productsState?.[sectionName] as DataProducts) || {};
  usePageScrollPosition(!!currentProducts?.length);

  const limit = useRef(limitCurrentProducts);
  const skip = useRef(skipCurrentProducts);

  useEffect(() => {
    if (!scrollbarValues) return;

    scrollBarRef.current = scrollbarValues;
  }, [scrollbarValues]);

  useEffect(() => {
    if (currentProducts.length <= 10) {
      canLoadMore.current = true;
      loadingProducts.current = false;
    }
  }, [currentProducts.length]);

  const loadProducts = (listProducts: Book[], initLoad: boolean): void => {
    const { listProductsId, products } = productsToShow(
      listProducts,
      initLoad,
      currentProducts,
      state.listProductsId,
      asPath.includes('resultados'),
      sectionName?.includes('filters'),
    );

    if (!listProducts?.length) {
      canLoadMore.current = false;
    }

    setState((prevState) => ({
      ...prevState,
      listProductsId,
      loading: false,
    }));

    const sectionCorrectName = initLoad
      ? getSectionNameIfFilter((!!order || !!Object.keys(filters).length) && doTheSearch) ||
        sectionName
      : sectionName;

    setProductsState(sectionCorrectName, {
      books: products,
      limit: limit.current,
      skip: skip.current,
    });

    setLoading(false);
    setListingLoading(false);
    setLoaderResult(false);
    loadingProducts.current = false;
  };

  const onCompleteGetProducts = (listBooks: Book[], initialLoad: boolean): void => {
    if (category && listBooks[0]?.product_type !== category) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));

      setLoading(false);
      setListingLoading(false);
      return;
    }

    loadProducts(listBooks, initialLoad);
  };

  const updateToCorrectLimitAndSkip = (initialLoad: boolean): void => {
    if (initialLoad) {
      skip.current = initialPagination.skip;
      limit.current = initialPagination.limit;
      return;
    }

    if (
      limit.current === initialPagination.limit &&
      initialPagination.limit !== noInitialPagination.limit
    ) {
      limit.current = noInitialPagination.limit;
      return;
    }

    skip.current += limit.current;
  };

  const getCurrentProducts = async (initialLoad = true): Promise<void> => {
    updateToCorrectLimitAndSkip(initialLoad);

    let areaValue = area;
    if (checkAreaType(area)) {
      areaValue = null;
    }

    const variables = currentProductsVariable(
      filters,
      order,
      sectionSpanishWithAreasOfInterest.includes(asPath),
      areaValue,
      limit.current,
      skip.current,
      category,
      asPath.includes('saldos-y-ofertas') ? 'all' : type,
      asPath.includes('saldos-y-ofertas') ? '' : search,
      area
        ? !isAreaPage
        : state.noPublish ||
            (filters && filters.Tags?.some((tag) => tag.items?.includes('SIN PUBLICAR'))),
    );

    if (asPath.includes('saldos-y-ofertas')) {
      delete variables.searchGeneral;
    }

    loadingProducts.current = true;

    const { data: products, ok } = await getProducts(variables);
    if (!ok) return;

    onCompleteGetProducts(products, initialLoad);
    if (variables.product_type.length === 1 && asPath.includes('resultados') && initialLoad) {
      const { data, ok: okCount } = await getProductsCount(variables);

      if (!okCount) return;

      const totalProduct = ok ? data : currentProducts?.length;

      setTotalCurrentProducts(totalProduct);
    }
  };

  useEffect(() => {
    if (
      !doTheSearch ||
      !sectionName ||
      (doTheSearch && sectionName !== 'filters') ||
      (isTheActualPageSave() && sectionName === 'filters')
    ) {
      return;
    }
    updateToCorrectLimitAndSkip(true);

    canLoadMore.current = true;

    setState((prevState) => ({
      ...prevState,
      initialLoad: true,
      listProductsId: [],
    }));
    setLoaderResult(true);

    getCurrentProducts();
  }, [order, filters, doTheSearch, sectionName]);

  useEffect(() => {
    updateToCorrectLimitAndSkip(true);
    canLoadMore.current = true;

    const areaIdStorage = getDataFromStorage(NameStorage.areaOfInterest);

    if (
      areaIdStorage &&
      sectionWithAreasOfInterest.includes(category) &&
      asPath.includes('resultados')
    ) {
      setLoaderResult(true);
    }
  }, [category]);

  useEffect(() => {
    if (!_loading || loaderResult) return;
    getCurrentProducts(false);
  }, [_loading]);

  const onScroll = (): void => {
    if (_loading || !canLoadMore.current || state?.loading || loadingProducts.current) {
      return;
    }

    if (hasReachedBottom(scrollBarRef.current)) {
      setState((prevState) => ({ ...prevState, loading: true, initialLoad: false }));
      setLoading(true);
    }
  };

  const activateScroll = (): void => {
    document.addEventListener('bp.scroll', onScroll, true);
  };

  useEffect(() => {
    activateScroll();
    if (!existFilter() || !pageScrollPosition?.pageName?.includes(asPath)) {
      setState((prevState) => ({
        ...prevState,
        listProductsId: [],
      }));
    }

    return (): void => {
      setState((prevState) => ({ ...prevState, listProductsId: [] }));
      document.removeEventListener('bp.scroll', onScroll);
    };
  }, [asPath]);

  useEffect(() => {
    let { noPublish } = state;

    if (filters?.Tags?.length) {
      noPublish = filters.Tags.some((tag) => tag.items?.includes('SIN PUBLICAR'));
    } else if (state.noPublish) noPublish = false;

    setState((prevState) => ({
      ...prevState,
      noPublish,
    }));
  }, [filters]);

  const goBack = (): void => {
    const tags = filters?.Tags?.filter((t) => !t?.items?.includes('SIN PUBLICAR'));
    setState((prevState) => ({ ...prevState, listProductsId: [] }));
    changeFilters({ prop: 'Tags', value: tags }, null, true);
    changeDoTheSearch(false);
  };

  const getResultRoute = (): string => {
    const [, route] = pathname.split('/');
    return route === 'resultados' ? 'results' : '';
  };

  const currentProductClicked = (product: Book): void => {
    setProductSelected(product);
  };

  if (loaderResult && currentProducts?.length === 0) {
    return (
      <div className="row productList fixed paddingList">
        <Loader isSpinner />
      </div>
    );
  }

  if (currentProducts?.length) {
    const returnTitle = asPath.split('/').includes('resultados')
      ? asPath.split('/')[2]
      : asPath.split('/')[1].split('-')[0];

    return (
      <div key="serverProducts" id="serverProducts">
        <div className="proxiHeader">
          <h2 className="pageTitleArea">{title.replace('?expanded=true', '')}</h2>

          {state.noPublish ? (
            <Button onClick={goBack} className="rounded bordered" iconClass="arrow-left" iconLeft>
              {`Volver a ${returnTitle === 'material' ? 'materiales' : returnTitle} ya publicados`}
            </Button>
          ) : null}
        </div>

        <div className={`row productList paddingList ${getResultRoute()}`}>
          {currentProducts?.map((element) => (
            <ProductPreview
              results
              id={`l_p_${element.id}`}
              key={`${element?.id} - ${element?.ean}`}
              element={element}
              withBorder
              category={category}
              onClick={currentProductClicked}
              isLoading={isEqual(element, productSelected)}
            />
          ))}

          {state.loadingBack ? <Loader isSpinner className="cart superBig" /> : null}

          {currentProducts?.length && currentProducts[0]?.not_released ? (
            <div className="actionsHolder backFromCart">
              <Button onClick={goBack} className="rounded bordered" iconClass="arrow-left" iconLeft>
                Volver
              </Button>
            </div>
          ) : null}

          {state.loadingBack ? <Loader isSpinner className="cart superBig" /> : null}
          {state.loading ? <Loader isSpinner /> : null}
        </div>
      </div>
    );
  }

  return (
    <div className="row productList">
      {state.loading || loaderResult ? (
        <Loader isSpinner />
      ) : (
        <p className="counter">
          {` No hay resultados con esta búsqueda en ${search || state.areaBeautify}`}
        </p>
      )}
    </div>
  );
};

export default React.memo(ProductListing);
